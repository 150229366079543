import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import Image from "../../components/image"

import SEO from "../../components/seo"
import Hero from "../../components/hero"
import Features from "../../components/features"

const ThanksPage = () => (
  <Layout>
    <SEO title="Thanks" />
    <Hero
      headline={<span>Great chatting. We’re excited to work for you.</span>}
      dek="What’s next? Check your email for a confirmation link."
      bullets={[
        {icon: "ti-arrow-right", text: "Just click to confirm your email and set your password."},
        {icon: "ti-arrow-right", text: "We’ll review your task and let you know if we need more details."},
        {icon: "ti-arrow-right", text: "We’ll start working. Your dedicated Managed Commerce expert awaits."}
    ]}/>
  </Layout>
)

export default ThanksPage
